import React from "react";
import logo from "./../assets/images/svgImages/logo.svg";
import Ellipse from "../assets/images/svgImages/Ellipse.svg";
import circle from "../assets/images/svgImages/circle.svg";
import car from "../assets/images/svgImages/car.svg";
import arrow from "../assets/images/svgImages/arrow.svg";
import person from "../assets/images/svgImages/person.svg";
import temple from "../assets/images/svgImages/temple.svg";
import intarrow from "../assets/images/svgImages/intarrow.svg";
import dashboard from "../assets/images/svgImages/dashboard.svg";
import users from "../assets/images/svgImages/users.svg";
import places from "../assets/images/svgImages/places.svg";
import tours from "../assets/images/svgImages/tours.svg";
import bundles from "../assets/images/svgImages/bundles.svg";
import admin from "../assets/images/svgImages/admin.svg";
import page from "../assets/images/svgImages/page.svg";
import settings from "../assets/images/svgImages/settings.svg";
import logout from "../assets/images/svgImages/logout.svg";
import calendar from "../assets/images/svgImages/calendar.svg";
import search from "../assets/images/svgImages/search.svg";
import add from "../assets/images/svgImages/add.svg";
import berna from "../assets/images/pngImages/berna.png";
import closecircle from "../assets/images/svgImages/closecircle.svg";
import select from "../assets/images/svgImages/select.svg";
import itza from "../assets/images/pngImages/itza.png";
import smallcar from "../assets/images/svgImages/smallcar.svg";
import clock from "../assets/images/svgImages/clock.svg";
import flag from "../assets/images/svgImages/flag.svg";
import stars from "../assets/images/svgImages/stars.svg";
import edit from "../assets/images/svgImages/edit.svg";
import remove from "../assets/images/svgImages/remove.svg";
import admincrose from "../assets/images/svgImages/admincrose.svg";
import adminicon from "../assets/images/svgImages/adminicon.svg";
import yStar from "../assets/images/svgImages/yStar.svg";
import star from "../assets/images/svgImages/star.svg";
import deletes from "../assets/images/svgImages/delete.svg";
import walking from "../assets/images/svgImages/walking.png";
import bookings from "../assets/images/svgImages/bookings.svg";
import editMail from "../assets/images/svgImages/redeemMail.svg";

const Images = {
  logo,
  Ellipse,
  circle,
  car,
  arrow,
  person,
  editMail,
  temple,
  intarrow,
  dashboard,
  users,
  places,
  tours,
  bundles,
  admin,
  page,
  settings,
  logout,
  calendar,
  search,
  add,
  berna,
  closecircle,
  select,
  itza,
  smallcar,
  clock,
  flag,
  stars,
  edit,
  remove,
  admincrose,
  adminicon,
  yStar,
  star,
  deletes,
  walking,
  bookings,
};
export default Images;
