import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
import { STORAGE_PATH, bundlesInput } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBox from "../../checkbox/CheckBox";
import services from "../../../services/services";
import { useAppDispatch } from "../../../store/app/store";
import { getallBundlesThank } from "../../../store/features/getBundlesSlice";
import { toast } from "react-toastify";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import { useSelector } from "react-redux";
const EditBundule = ({ data }: any) => {
  const { Tours } = useSelector((state: any) => state.tours);
  const dispatch: any = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [File, setFile] = useState<any>({});
  const [deletedtoursIds, setDeletedtoursIds] = useState<any>([]);
  const [bundleTours, setBundleTours] = useState<any>(
    // data?.bundelDetails?.map((elm: any) => elm?.tourBundelsDetails)
    []
  );
  const [tourInBundle, setTourInBundle] = useState<any>({});

  console.log("data in edit :", Tours?.data?.data);
  console.log("bundleTours in edit :", bundleTours);

  useEffect(() => {
    if (showModal) {
      const tours: any = {};
      data?.bundelDetails?.forEach((elm: any) => {
        tours[elm?.tourBundelsDetails?.id] = true;
      });

      setTourInBundle(tours);
      const toursData = data?.bundelDetails?.map((elm: any) => ({
        originTourId: elm?.tourBundelsDetails?.id,
        ...elm,
      }));
      setBundleTours(toursData);
    }
  }, [showModal]);

  const handleAddBunleTours = (tour: any) => {
    setBundleTours((prev: any) => [
      ...prev,
      { originTourId: tour?.id, ...tour },
    ]);
    setTourInBundle({
      ...tourInBundle,
      [tour?.id]: true,
    });
  };
  const handleFilterBundleTours = (tour: any) => {
    if (tour?.bundelId) {
      setDeletedtoursIds([...deletedtoursIds, tour]);
    }
    setBundleTours(
      bundleTours.filter(
        (item: any) => item?.originTourId !== tour?.originTourId
      )
    );
    delete tourInBundle[tour?.originTourId];
  };
  console.log("data in formik :", data);
  const formik = useFormik({
    initialValues: {
      name: data?.name,
      productId: data?.productId,
      imageUrl: data?.imageUrl,
      description: data?.description,
      retailPrice: data?.retailPrice,
      sellingPrice: data?.sellingPrice,
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("This field is required"),
      productId: Yup.string()
        .trim()
        .required("This field is required")
        .test("no-spaces", "Name cannot contain spaces", (value: any) => {
          return value && !/\s/.test(value);
        }),
      description: Yup.string().trim().required("This field is required"),
      sellingPrice: Yup.number()
        .typeError("Please enter a number value only")
        .nullable()
        .transform((value, originalValue) => {
          console.log("originalValue: ", originalValue);
          return originalValue.trim() === "" ? null : parseFloat(originalValue);
        })
        .lessThan(
          Yup.ref("retailPrice"),
          "Selling price is less than or equal to retail price"
        ),
        retailPrice: Yup.number()
        .notOneOf([0], "Zero and negative values not accepted")
        .required("This field is  required"),
      imageUrl: Yup.string().trim().required("This Field is  Required"),
    }),
    onSubmit: (values) => {
      if (bundleTours?.length) {
        handleAddTour({
          ...values,
          // retailPrice: values.retailPrice || null,
          // sellingPrice: parseInt(values?.sellingPrice),
          imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        });
      } else {
        toast.error("Please add Tours");
      }
    },
    enableReinitialize: true,
  });

  const deleteImageUri = async () => {
    setFile({
      ...File,
      imageUrl: "",
    });
    formik.setFieldValue("imageUrl", "");
  };

  const handleAddTour = async (payload: any) => {
    const finalPayoad = {
      ...payload,
      bundelsId: data?.id,
      deletedtoursIds: deletedtoursIds.map((item: any) => item.id),
      bundlesTours: bundleTours?.map((item: any) => {
        const payload = {
          placeId: item?.placeId,
          tourId: item?.id,
          id: item?.bundleInTourId,
        };
        if (item?.bundleInTourId === null) {
          payload["id"] = null;
        }
        return payload;
      }),
    };

    const res: any = await services.updateBundles(finalPayoad);

    if (res?.status) {
      toast.success("Bundles Updated Successfully");
      dispatch(getallBundlesThank());
      setShowModal(false);
    } else {
      toast.dismiss();
      toast.error("someThing Went Wrong");
    }
  };

  const handleChangeOnFile = async (e: any) => {
    if (e.target.accept == "image/*") {
      formik.values.imageUrl = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors.imageUrl = "";
      } else {
        formik.values.imageUrl = "";
        formik.errors.imageUrl = "Please select correct img";
      }
    }
  };
  useEffect(() => {
    formik?.handleReset(formik.values);
    if (showModal) {
      (formik as any).values = {
        name: data?.name,
        imageUrl: data?.imageUrl,
        description: data?.description,
        retailPrice: data?.retailPrice,
        sellingPrice: data?.sellingPrice,
        productId: data?.productId,
      };
    }
  }, [showModal]);
  return (
    <>
      <img
        src={Images.edit}
        alt="edit"
        onClick={() => setShowModal(true)}
        className="ml-2"
      />
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto">
              {/*content*/}

              {/*header*/}

              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Bundles</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}
              <form onSubmit={formik.handleSubmit} className="px-12 py-9">
                <div className="">
                  {bundlesInput.map((elm: any) =>
                    elm?.type == "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        deleteImageUri={deleteImageUri}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : (
                      <InputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                  <div className="w-full justify-between mt-4 ">
                    <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto">
                      Tours
                    </label>
                    <CheckBox
                      data={Tours?.data?.data}
                      handleFilterBundleTours={handleFilterBundleTours}
                      handleAddBunleTours={handleAddBunleTours}
                      tourInBundle={tourInBundle}
                      bundleTours={bundleTours}
                      className="overflow-x-auto w-full"
                    />
                  </div>

                  {/* <div>
                  <AddStopsComponent />{" "}
                </div> */}
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setBundleTours([]);
                      setShowModal(false);
                      setTourInBundle({});
                      // setBundleTours([]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditBundule;
